<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                      <div class="product-img">
                        <img
                          src="https://kmcorporate.com/wp-content/uploads/2021/06/Contact-ST5-rev.1-210609-def-1024x696.jpg"
                          alt="img-4" class="img-fluid mx-auto d-block" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="mt-4">

                  <p style="font-size: 16px; color: black; font-weight: 400">
                    With its capacity to accommodate up to 5 processing stations, the CONTACT ST5 can be the excellent
                    solution to customer’s need. </p>
                </div>
                <br />
                <div class="mt-4">

                  <p style="font-size: 16px; color: black; font-weight: 400">
                    The CONTACT ST5 can produce standard both end crimping, doublecrimp connections with three
                    diffferent terminals or apply seals or sleeve on one or both end of the wire. The user interfeace is
                    designed for a very quick and friendly data input. All processing positions and associated
                    parameters are programmable and all data can be stored ensuring the adjusting and programming times
                    to minimum. Our range Contact can be equipped with a variety of options that offers the highest
                    degrees of flexibility, so to meet the customer’s specific needs; crimping units (20 kN or 30 kN)
                    can be integrated with a crimping force monitoring to guarantee a perfect quality and at the same
                    time to give information about the production. Our system doesn’t reduce in any way the piece output
                    of the machine. Seal insertion unit or sleeve insertion unit can be mounted on this range as well as
                    the twisting device or tinning station with integral flux unit. Another option available is our Dual
                    device that allows double crimp connections in either a horizontal or a vertical arrangement, as
                    required. The machine can be equipped with a wire feeding system by four rollers (standard feature)
                    or a wire feeding system by belts (on request). </p>
                </div>
                <br />




                <p style="font-size: 14px; color: black; font-weight: 400">
                  Data and measurements are indicative and subject to change
                  without notice. Some particular types of cables may not be
                  machined even though they fall within the range of sections
                  indicated. KM Corporate will still be happy to carry out tests
                  and supply wire-worked samples
                </p>
              </div>
            </div>
            <!-- end row -->
            <br />
            <br />
            <br />
            <div class="row">
              <div class="col-5"></div>
              <div class="col-7">
                <button @click="file()" class="btn btn-primary" style="width:100%"><i class="fa fa-download"></i>
                  Download The Technical Sheet</button>
              </div>
            </div>
            <br><br><br>
            <div class="row">
              <h1 style="color: black; font-weight: 800; margin-left: 40px" class="font-size-20 mb-3">
                Gallery

              </h1>

            </div>
            <br />

            <div class="row" style="margin-left:50px;">

              <vue-picture-swipe :items="items"></vue-picture-swipe>
            </div>
            <!-- end row -->

          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {

    Header,
    Footer,


  },
  data() {
    return {
      items: [{
        src: 'https://kmcorporate.com/wp-content/uploads/2021/06/Contact-ST5-rev.1-210609-def-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/Contact-ST5-rev.1-210609-def-150x150.jpg',
        w: 1200,
        h: 900,
        alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
      },
      {
        src: 'https://kmcorporate.com/wp-content/uploads/2021/08/CONTACT-ST5-PIANTA-rev.-3-210802-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/08/CONTACT-ST5-PIANTA-rev.-3-210802-150x150.jpg',
        w: 1200,
        h: 900
      },
      {
        src: 'https://kmcorporate.com/wp-content/uploads/2021/08/Contact-st5-optional-piantina-rev.-3-210802-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/08/Contact-st5-optional-piantina-rev.-3-210802-150x150.jpg',
        w: 1200,
        h: 900
      },
      {
        src: 'https://kmcorporate.com/wp-content/uploads/2021/07/ST5-KM-040-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/ST5-KM-040-150x150.jpg',
        w: 1200,
        h: 900
      },
      {
        src: 'https://kmcorporate.com/wp-content/uploads/2021/07/ST5-KM-035-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/ST5-KM-035-150x150.jpg',
        w: 1200,
        h: 900
      },
      {
        src: 'https://kmcorporate.com/wp-content/uploads/2021/07/ST5-KM-032-150x150.jpg',
        thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/ST5-KM-032-150x150.jpg',
        w: 1200,
        h: 900
      }
      ]
    };

  },

  methods: {
    file() {
      window.location.href = "https://kmcorporate.com/wp-content/uploads/2021/06/Scheda_Contact_St5_Rev-19-01-Low.pdf";

    }
  }
};
</script>